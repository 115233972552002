import { apiClient } from "./HttpService";

// 获取原厂库列表
export function fetchFactoryList(params) {
  return apiClient.get(
    `/factories?factoryName=${params.factoryName}&mobile=${params.mobile}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&userName=${params.userName}`
  );
}
// 获取原厂库详情
export function fetchFactoryDetail(factoryId) {
  return apiClient.get(`/factories/${factoryId}`);
}
// 新增原厂库
export function onAddFactory(form) {
  return apiClient.post("/factories", form);
}
// 修改原厂库
export function onModifyFactory(form) {
  return apiClient.put("/factories", form);
}
// 删除原厂库
export function onDeleteFactory(factoryId) {
  return apiClient.delete(`/factories/${factoryId}`);
}

// 查询原厂库物流信息
export function onSearchLogistics(params) {
  return apiClient.get(
    `/companies/${params.factoryId}/search?keyword=${params.keyword}`
  );
}
