<template>
  <a-layout-content>
    <!-- 顶部导航栏盒子 -->
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackList">未交付订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackDetail">订单详情</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>发货</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- 填写物流信息盒子 -->
    <section class="info-container">
      <a-row>
        <a-col>
          <div class="info-title">1、填写物流信息</div>
        </a-col>
      </a-row>
      <a-row>
        <a-form :form="logisticsForm" class="advanced-search-form">
          <a-row>
            <a-col :span="8">
              <a-form-item label="物流公司">
                <a-select
                  v-decorator="['logisticsName']"
                  show-search
                  :options="logisticsList"
                  @search="onSearch"
                  @change="onChangeLogisticsName"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="联系电话">
                <a-input
                  v-decorator="[
                    'logisticsTel',
                    {
                      rules: [{ validator: phoneCheck.bind(this) }]
                    }
                  ]"
                  class="numberIpt"
                  @change="onChangeLogisticsTel"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="物流单号">
                <a-input
                  v-decorator="['logisticsSn']"
                  @input="onInputLogisticsSn"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </section>
    <!-- 确认交易详情及收货地址盒子 -->
    <section class="info-container">
      <a-row>
        <a-col>
          <div class="info-title">2、确认交易详情及收货地址</div>
        </a-col>
      </a-row>
      <section>
        <div v-for="(item, index) in childOrderList" :key="index">
          <a-table
            class="commodity-table"
            :data-source="item.logisticsOrderSkuList"
            :columns="tableColumns"
            :loading="loading"
            row-key="skuId"
            :pagination="false"
          >
            <template slot="quantityShipped" slot-scope="text, record">
              <span v-if="record.quantityShipped">
                {{ record.quantityShipped }}
              </span>
              <span v-else>0</span>
            </template>
            <template slot="skuShippingQuantity" slot-scope="text, record">
              <span v-if="record.hasSn">-</span>
              <a-input
                v-else
                v-model.number="record.shippingQuantity"
                type="number"
                @blur="validateShippingQuantity(record)"
              />
            </template>
            <div
              slot="skuImages"
              v-viewer
              slot-scope="text, record"
              class="img-container"
            >
              <div
                v-if="record.fileList && record.fileList.length > 0"
                class="img-container"
              >
                <img
                  v-if="!record.fileList[0].thumbUrl"
                  :src="imageBaseUrl + record.fileList[0].fileUrl"
                  :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
                <img
                  v-else
                  :src="imageBaseUrl + record.fileList[0].thumbUrl"
                  :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                  alt="产品图片"
                  class="img-sku"
                />
              </div>
            </div>
            <template slot="title">
              <div class="buyer-info">
                <div class="label-name">
                  买家收货信息：
                  <span>
                    {{
                      item.consigneeAddress +
                        "，" +
                        item.consigneeName +
                        "，" +
                        item.consigneeTel
                    }}
                  </span>
                </div>
                <a-icon
                  v-if="judgeSenderAddressEdit(item.logisticsOrderSkuList)"
                  type="edit"
                  :style="{ fontSize: '16px' }"
                  @click="openReceiveSpellModal(item)"
                />
              </div>
              <div class="order-info">
                <a-row class="row">
                  <a-col :span="7">
                    <div class="label-name">
                      订单编号：
                      <span>{{ item.orderSn }}</span>
                    </div>
                  </a-col>
                  <a-col :span="7">
                    <div class="label-name">
                      创建时间：
                      <span>{{ item.createTime }}</span>
                    </div>
                  </a-col>
                  <a-col
                    v-if="item.totalQuantity !== item.totalQuantityShipped"
                    :span="10"
                    class="col"
                  >
                    <div class="label-name">编辑发货信息</div>
                    <a-icon
                      type="edit"
                      :style="{ fontSize: '16px' }"
                      class="icon-edit"
                      @click="onEditSerialSpell(item.totalQuantity, item)"
                    />
                  </a-col>
                </a-row>
              </div>
            </template>
          </a-table>
        </div>
      </section>
    </section>
    <!-- 确认发货信息盒子 -->
    <section class="last-info-container">
      <a-row>
        <a-col>
          <div class="info-title">3、确认发货信息</div>
        </a-col>
      </a-row>
      <div class="deliver-info">
        我的发货信息：
        <span>{{ senderAddress }}</span>
        <a-icon
          type="edit"
          :style="{ fontSize: '16px' }"
          @click="onEditMineInfo"
        />
      </div>
    </section>
    <!-- 底部按钮盒子 -->
    <div class="examine-container">
      <div class="button-container">
        <a-button class="button-item" @click="onBackDetail">取消</a-button>
        <a-popconfirm
          title="确认发货?"
          ok-text="确定"
          cancel-text="取消"
          placement="topRight"
          @confirm="onConfirmShipping"
        >
          <a-button type="primary" class="button-item">确认发货</a-button>
        </a-popconfirm>
      </div>
    </div>

    <!-- 收货地址修改弹窗 -->
    <a-modal
      title="修改收货地址"
      :visible="receiveVisible"
      @ok="handleOkReceiveAddress"
      @cancel="receiveVisible = false"
    >
      <a-form :form="editReceiveForm" class="receive-form">
        <a-form-item label="收货地址">
          <a-cascader
            v-model="areaCode"
            :options="areaTreeData"
            :field-names="areaTreeNames"
            placeholder="请选择区域"
            :allow-clear="false"
            @change="onChangePer"
          />
          <a-input
            v-decorator="[
              'detailAddress',
              {
                rules: [{ required: true, message: '请输入收货地址' }]
              }
            ]"
            placeholder="请输入详细地址"
          />
        </a-form-item>
        <a-form-item label="收货人姓名">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入收货人姓名' }]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="收货人电话">
          <a-input
            v-decorator="[
              'mobile',
              {
                rules: [{ required: true, message: '请输入收货人电话' }]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 发货地址选择弹窗 -->
    <a-modal
      title="请选择合适的地址"
      :visible="sendVisible"
      @cancel="sendVisible = false"
    >
      <div v-if="addressList.length > 0">
        <a-radio-group v-model="defaultSelect">
          <a-radio
            v-for="item in addressList"
            :key="item.addressId"
            :value="item.addressItem"
            @change="onChange($event, item)"
          >
            {{ item.addressItem }}
          </a-radio>
        </a-radio-group>
      </div>
      <a-empty v-else description="暂无地址，请去新增" />
      <template slot="footer">
        <a-button @click="onEnterAddress">
          地址库管理
        </a-button>
        <a-button type="primary" @click="onSureAddress">
          确定
        </a-button>
      </template>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { loadArea } from "../../services/DealerService";
import {
  fetchAddressList,
  onDeliverGoods,
  queryOrderInfo
} from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import { imageBaseUrl } from "../../services/HttpService";
import { onSearchLogistics } from "../../services/FactoryService";

const tableColumns = [
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: "10%"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "8%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "10%"
  },
  {
    title: "是否有序列号",
    dataIndex: "hasSnState",
    width: "8%"
  },
  {
    title: "已发货数量",
    dataIndex: "quantityShipped",
    scopedSlots: { customRender: "quantityShipped" },
    width: "10%"
  },
  {
    title: "装运体积（m³）",
    dataIndex: "packVolume",
    width: "14%"
  },
  {
    title: "商品总重量（kg）",
    dataIndex: "skuWeight",
    width: "14%"
  },
  {
    title: "购买数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "本次发货数量",
    dataIndex: "num",
    scopedSlots: { customRender: "num" },
    width: "10%"
  }
];

export default {
  data() {
    return {
      localArea: false,
      localConsigneeTel: "",
      localConsigneeAddress: "",
      localConsigneeName: "",
      imageBaseUrl, // 文件展示路径
      logisticsForm: this.$form.createForm(this), // 物流信息form
      tableData: [], // 废弃
      quantityShipped: "", // 已发货数量
      tableColumns, // 表格表头格式
      loading: false, // 订单信息加载动画
      receiveVisible: false, // 收货弹窗是否展示
      editReceiveForm: this.$form.createForm(this), // 收货地址修改form
      areaCode: [], // 选择的省市区code
      areaValues: [],
      areaTreeData: [], // 行政区数据
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      receiveAddress: "", // 收货地址
      areaValue: "", // 地址拼接数据
      sendVisible: false, // 发货弹窗是否展示
      senderAddress: "", // 发货信息
      addressList: [], // 发货地址列表
      selectValue: "",
      whetherPatchwork: false,
      orderSn: this.$route.params.orderSn, // 订单编号
      // logisticsList: [
      //   { value: "韵达快递", label: "韵达快递" },
      //   { value: "圆通快递", label: "圆通快递" },
      //   { value: "中通快递", label: "中通快递" },
      //   { value: "申通快递", label: "申通快递" },
      //   { value: "顺丰速运", label: "顺丰速运" },
      //   { value: "邮政快递", label: "邮政快递" },
      //   { value: "百世汇通", label: "百世汇通" },
      //   { value: "其他", label: "其他" }
      // ], // 快递物品选择
      logisticsList: [],
      totalQuantity: 0, // 已发货商品数量
      consigneeInfo: {
        consigneeCountyCode: "",
        consigneeDetailAddress: "",
        consigneeName: "",
        consigneeTel: "",
        consigneeAddress: ""
      }, // 收货信息，弹窗展示用
      senderInfo: {
        senderCountryCode: "",
        senderDetailAddress: "",
        senderName: "",
        senderTel: ""
      }, // 发货信息
      defaultSelect: "", // 发货地址的默认选中id
      orderType: "", // 订单类型
      childOrderList: [], // 子集订单列表，现使用该字段渲染页面订单数据
      selectOrder: "", // 选择的订单（订单编号）
      searchList: []
    };
  },
  // computed: {
  //   // 判断订单是否是拼单订单
  //   whetherPatchwork() {
  //     return (
  //       this.childOrderList &&
  //       this.childOrderList.length > 0 &&
  //       this.childOrderList[0].whetherPatchwork
  //     );
  //   }
  // },
  created() {
    if (localStorage.getItem("consigneeAddress")) {
      this.localArea = true;
    }
  },
  mounted() {
    this.onLoadArea();
    this.onLoad();
    this.onReload();
    this.onLoadAddressList();
    this.localConsigneeAddress = localStorage.getItem("consigneeAddress");
    this.localConsigneeTel = localStorage.getItem("consigneeTel");
    this.localConsigneeName = localStorage.getItem("consigneeName");
  },
  // 离开当前页面，部分存储数据需要删除
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== "DeliverGoods" &&
      to.name !== "EditSerial" &&
      to.name !== "EditSerialSpell" &&
      to.name !== "ShippingAddress"
    ) {
      localStorage.removeItem("serialList");
      localStorage.removeItem("logisticsName");
      localStorage.removeItem("logisticsTel");
      localStorage.removeItem("logisticsSn");
      localStorage.removeItem("senderInfo");
      localStorage.removeItem("senderAddress");
      localStorage.removeItem("serialSpellList");
      localStorage.removeItem("skuCodeList");
      localStorage.removeItem("consigneeAddress");
      localStorage.removeItem("localAreaCode");
      localStorage.removeItem("consigneeName");
      localStorage.removeItem("consigneeDetailAddress");
      localStorage.removeItem("consigneeTel");
      localStorage.removeItem("consigneeCountyCode");
      localStorage.removeItem("areaValue");
      localStorage.removeItem("childOrderList");
    }
    next();
  },
  methods: {
    // 数据重新加载，跳转到指定页面后，数据会保存下来，重新返回后需要重新渲染
    onReload() {
      if (localStorage.getItem("logisticsName") !== "null") {
        this.logisticsForm.setFieldsValue({
          logisticsName: localStorage.getItem("logisticsName")
        });
      } else {
        this.logisticsForm.setFieldsValue({
          logisticsName: ""
        });
      }
      if (localStorage.getItem("logisticsTel") !== "null") {
        this.logisticsForm.setFieldsValue({
          logisticsTel: localStorage.getItem("logisticsTel")
        });
      } else {
        this.logisticsForm.setFieldsValue({
          logisticsTel: ""
        });
      }
      if (localStorage.getItem("logisticsSn") !== "null") {
        this.logisticsForm.setFieldsValue({
          logisticsSn: localStorage.getItem("logisticsSn")
        });
      } else {
        this.logisticsForm.setFieldsValue({
          logisticsSn: ""
        });
      }
      if (localStorage.getItem("senderAddress") !== null) {
        this.senderAddress = localStorage.getItem("senderAddress");
      }
      if (localStorage.getItem("senderInfo") !== null) {
        this.senderInfo = JSON.parse(localStorage.getItem("senderInfo"));
      }
    },
    // 页面加载渲染数据
    onLoad() {
      queryOrderInfo(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          data.forEach(item => {
            item.createTime = tsFormat(item.createTime);
          });
          const childOrderList = localStorage.getItem("childOrderList");
          this.childOrderList =
            childOrderList && childOrderList.trim().length > 0
              ? JSON.parse(childOrderList)
              : data;
          this.childOrderList.forEach(ele => {
            // 处理发货数量
            if (ele.logisticsOrderSkuList.length > 0) {
              ele.totalQuantity = 0;
              ele.totalQuantityShipped = 0;
              ele.logisticsOrderSkuList.forEach(sku => {
                ele.totalQuantity = ele.totalQuantity + sku.purchaseQuantity;
                ele.totalQuantityShipped =
                  ele.totalQuantityShipped + sku.quantityShipped;
                if (localStorage.getItem("serialSpellList") !== null) {
                  const list = JSON.parse(
                    localStorage.getItem("serialSpellList")
                  );
                  let num = 0;
                  list.forEach(orderGoods => {
                    if (String(orderGoods.orderSn) === String(ele.orderSn)) {
                      orderGoods.list.forEach(skuGoods => {
                        if (
                          String(skuGoods.productNumber) === String(sku.skuCode)
                        ) {
                          num = num + 1;
                        }
                      });
                    }
                  });
                  sku.num = num;
                }
                sku.hasSnState = sku.hasSn ? "是" : "否";
              });
            }
          });
          this.whetherPatchwork = this.childOrderList[0].whetherPatchwork;
        }
      });
    },
    // 返回到未交付订单列表
    onBackList() {
      this.$router.push({ name: "UndeliveredOrder" });
    },
    // 面包屑跳转
    onBackDetail() {
      if (this.whetherPatchwork) {
        this.$router.replace({
          name: "SpellDetail",
          params: { orderSn: this.orderSn }
        });
      } else {
        this.$router.replace({
          name: "UndeliveredDetail",
          params: { procureOrderSn: this.orderSn }
        });
      }
    },
    // 整理发货单中的物品序列号数据
    extractGoodsSnForm: function(
      item,
      orderShippingForm,
      logisticsOrderFormList
    ) {
      // shippingQuantity
      const shippingSkuQuantityList = [];
      item.logisticsOrderSkuList.forEach(sku => {
        if (!isNaN(sku.shippingQuantity)) {
          shippingSkuQuantityList.push({
            skuCode: sku.skuCode,
            quantity: sku.shippingQuantity
          });
        }
      });
      orderShippingForm.shippingSkuQuantityList = shippingSkuQuantityList;
      logisticsOrderFormList.push(orderShippingForm);

      let serialSpellList = [];
      if (localStorage.getItem("serialSpellList") !== null) {
        serialSpellList = JSON.parse(localStorage.getItem("serialSpellList"));
        if (serialSpellList.length > 0) {
          serialSpellList.forEach(ele => {
            const deliveredGoodsFormList = [];
            if (ele.orderSn === item.orderSn) {
              if (ele.list.length > 0) {
                ele.list.forEach(item => {
                  if (item.subGoodsList && item.subGoodsList.length > 0) {
                    item.subGoodsList.forEach(ele => {
                      ele.skuCode = ele.productNumber;
                      ele.skuSn = ele.seriesNumber;
                      ele.pskuSn = item.seriesNumber;
                    });
                  }
                  const param = {
                    skuCode: item.productNumber,
                    skuSn: item.seriesNumber,
                    pskuSn: null,
                    batchNo: item.batchNo,
                    quantity: item.quantity,
                    subGoodsList: item.subGoodsList
                  };
                  deliveredGoodsFormList.push(param);
                });
              }
              console.log(deliveredGoodsFormList);
              orderShippingForm.deliveredGoodsFormList = deliveredGoodsFormList;
            }
          });
        }
      }
    },
    // 整理发货单数据
    buildLogisticsOrderFormList: function() {
      const logisticsOrderFormList = [];
      if (this.childOrderList.length > 0) {
        this.childOrderList.forEach(order => {
          const orderShippingForm = {
            consigneeCountyCode: order.consigneeCountyCode,
            consigneeDetailAddress: order.consigneeDetailAddress,
            consigneeAddress: order.consigneeAddress,
            consigneeName: order.consigneeName,
            consigneeTel: order.consigneeTel,
            orderSn: order.orderSn,
            updateReceiverFlag: order.updateReceiverFlag
          };
          this.extractGoodsSnForm(
            order,
            orderShippingForm,
            logisticsOrderFormList
          );
        });
      }
      return logisticsOrderFormList;
    },
    // 发货订单信息中是否设置了发货商品信息
    hasShippingSku(logisticsOrderFormList) {
      if (logisticsOrderFormList) {
        logisticsOrderFormList.forEach(orderShippingConfig => {
          // 直接输入数量的情况，数量是否输入
          if (
            orderShippingConfig.shippingSkuQuantityList &&
            orderShippingConfig.shippingSkuQuantityList.length > 0
          ) {
            orderShippingConfig.shippingSkuQuantityList.forEach(
              skuShippingQuantity => {
                if (!isNaN(skuShippingQuantity.quantity)) {
                  return true;
                }
              }
            );
          }
          // 设置序列号的情况，序列号是否设置
          if (
            orderShippingConfig.deliveredGoodsFormList &&
            orderShippingConfig.deliveredGoodsFormList.length > 0
          ) {
            return true;
          }
        });
        return true;
      }
      return false;
    },
    // 确认发货
    onConfirmShipping() {
      this.$store.state.isModify = false;
      this.logisticsForm.validateFields((err, values) => {
        if (!err) {
          const logisticsOrderFormList = this.buildLogisticsOrderFormList();
          if (!this.hasShippingSku(logisticsOrderFormList)) {
            this.$message.info("请填写发货商品信息");
            return;
          }
          if (
            !this.senderAddress ||
            this.senderAddress === "暂无发货信息" ||
            !this.senderInfo
          ) {
            this.$message.info("请选择发货信息");
          } else {
            const params = {
              logisticsName: values.logisticsName,
              logisticsOrderFormList: logisticsOrderFormList,
              logisticsSn: values.logisticsSn,
              logisticsTel: values.logisticsTel,
              senderCountryCode: this.senderInfo.senderCountryCode,
              senderDetailAddress: this.senderInfo.senderDetailAddress,
              senderName: this.senderInfo.senderName,
              senderTel: this.senderInfo.senderTel
            };
            console.log("发货参数", params);
            onDeliverGoods(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("发货成功");
                if (this.whetherPatchwork) {
                  this.$router.replace({
                    name: "SpellDetail",
                    params: { orderSn: this.orderSn }
                  });
                  localStorage.removeItem("serialSpellList");
                } else {
                  this.$router.replace({
                    name: "UndeliveredDetail",
                    params: { procureOrderSn: this.orderSn }
                  });
                }
              }
            });
          }
        }
      });
    },
    // 打开修改收货信息弹窗
    openReceiveModal() {
      this.receiveVisible = true;
      setTimeout(() => {
        this.editReceiveForm.setFieldsValue({
          detailAddress: this.consigneeInfo.consigneeDetailAddress,
          name: this.consigneeInfo.consigneeName,
          mobile: this.consigneeInfo.consigneeTel
        });
      }, 100);
    },
    // 点击打开修改收货人信息弹窗
    openReceiveSpellModal(item) {
      this.areaValue = "";
      this.selectOrder = item.orderSn;
      this.areaCode = [
        item.consigneeProvinceCode,
        item.consigneeCityCode,
        item.consigneeCountyCode
      ];
      if (item.consigneeProvinceName === item.consigneeCityName) {
        this.areaValue = `${item.consigneeProvinceName} ${item.consigneeCountyName}`;
      } else {
        this.areaValue = `${item.consigneeProvinceName} ${item.consigneeCityName} ${item.consigneeCountyName}`;
      }
      this.receiveVisible = true;
      setTimeout(() => {
        this.editReceiveForm.setFieldsValue({
          detailAddress: item.consigneeDetailAddress,
          name: item.consigneeName,
          mobile: item.consigneeTel
        });
      }, 100);
    },
    // 修改收货信息
    handleOkReceiveAddress() {
      this.editReceiveForm.validateFields((err, values) => {
        if (!err) {
          if (values.mobile.length !== 11) {
            this.$message.info("请输入正确的手机号");
          } else {
            if (this.childOrderList.length > 0) {
              this.childOrderList.forEach(item => {
                if (item.orderSn === this.selectOrder) {
                  // 需要修改收货人地址
                  this.$set(item, "updateReceiverFlag", true);
                  item.receiveAddress = `${this.areaValue +
                    values.detailAddress}，${values.name}，${values.mobile}`;
                  item.consigneeCountyCode = this.areaCode[2];
                  item.consigneeProvinceCode = this.areaCode[0];
                  item.consigneeCityCode = this.areaCode[1];
                  if (this.areaValues.length > 0) {
                    item.consigneeProvinceName = this.areaValues[1];
                    item.consigneeCityName = this.areaValues[2];
                    item.consigneeCountyName = this.areaValues[3];
                  }
                  this.areaValues = [];
                  if (!this.areaValue) {
                    const firstAddress = item.consigneeAddress.split(" ");
                    firstAddress.splice(-1, 1);
                    this.areaValue = firstAddress.join(" ");
                  }
                  item.consigneeAddress = this.areaValue
                    ? `${this.areaValue} ${values.detailAddress}`
                    : item.consigneeAddress;
                  item.consigneeDetailAddress = values.detailAddress;
                  item.consigneeName = values.name;
                  item.consigneeTel = values.mobile;
                  this.localConsigneeName = item.consigneeName;
                  this.localConsigneeTel = item.consigneeTel;
                }
              });
            }
            localStorage.setItem(
              "childOrderList",
              JSON.stringify(this.childOrderList)
            );
            // else {
            //   this.consigneeInfo = {
            //     consigneeCountyCode: this.areaCode[2],
            //     consigneeDetailAddress: values.detailAddress,
            //     consigneeName: values.name,
            //     consigneeTel: values.mobile
            //   };
            //   this.receiveAddress = `${this.areaValue +
            //     values.detailAddress}，${values.name}，${values.mobile}`;
            // }
            this.receiveVisible = false;
          }
        }
      });
    },
    // 收货行政区域选择触发
    onChangePer(value, selectOptions) {
      this.areaCode = value;
      this.areaValue = "";
      if (selectOptions.length > 0) {
        selectOptions.forEach(item => {
          this.areaValue = `${this.areaValue} ${item.areaName}`;
        });
        const arrValue = this.areaValue.split(" ");
        this.areaValues = arrValue;
        if (arrValue[2] === arrValue[1]) {
          arrValue.splice(1, 1);
          this.areaValue = arrValue.join(" ");
        }
      }
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    // 发货弹窗展示
    onEditMineInfo() {
      this.sendVisible = true;
    },
    // 进入发货信息编辑页面
    onEnterAddress() {
      this.$store.state.isModify = false;
      this.$router.push({
        name: "ShippingAddress",
        params: { procureOrderSn: this.orderSn }
      });
    },
    // 获取当前原厂的发货地址列表
    onLoadAddressList() {
      const factoryId = localStorage.getItem("factoryId");
      fetchAddressList(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              if (
                item.senderProvinceName ||
                item.senderCityName ||
                item.senderCountyName
              )
                if (item.senderProvinceName === item.senderCityName) {
                  item.area = `${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                } else {
                  item.area = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                }
              item.addressItem = `${item.area}，${item.senderName}，${item.senderTel}`;
            });
          }
          this.addressList = data;
          if (!localStorage.getItem("senderAddress")) {
            if (this.senderInfo) {
              if (this.addressList.length > 0) {
                this.addressList.forEach(item => {
                  if (item.defaultFlag) {
                    this.defaultSelect = item.addressItem;
                    this.selectValue = this.defaultSelect;
                    this.senderInfo = {
                      senderCountryCode: item.countyCode,
                      senderDetailAddress: item.detailAddress,
                      senderName: item.senderName,
                      senderTel: item.senderTel
                    };
                    if (item.senderProvinceName === item.senderCityName) {
                      this.senderAddress = `${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}，${item.senderName}，${item.senderTel}`;
                    } else {
                      this.senderAddress = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}，${item.senderName}，${item.senderTel}`;
                    }
                  }
                });
              }
            } else {
              this.senderAddress = "暂无发货信息";
            }
          }
        }
      });
    },
    // 选择发货地址信息
    onSureAddress() {
      if (this.selectValue === "") {
        this.$message.info("请选择一个发货地址");
      } else {
        this.senderAddress = this.selectValue;
        this.sendVisible = false;
        localStorage.setItem("senderAddress", this.senderAddress);
        localStorage.setItem("senderInfo", JSON.stringify(this.senderInfo));
      }
    },
    // 选择发货地址
    onChange(e, item) {
      this.selectValue = e.target.value;
      this.senderInfo = {
        senderCountryCode: item.countyCode,
        senderDetailAddress: item.detailAddress,
        senderName: item.senderName,
        senderTel: item.senderTel
      };
    },
    // 编辑商品序列号
    onEditSerialSpell(totalQuantity, item) {
      this.$store.state.isModify = false;
      this.$router.push({
        name: "EditSerialSpell",
        params: {
          orderSn: this.orderSn,
          childrenOrderSn: item.orderSn,
          totalQuantity: totalQuantity,
          quantityShipped: item.totalQuantityShipped
            ? item.totalQuantityShipped
            : 0
        }
      });
      const skuCodeList = [];
      if (item.logisticsOrderSkuList && item.logisticsOrderSkuList.length > 0) {
        item.logisticsOrderSkuList.forEach(ele => {
          const param = {
            skuCode: ele.skuCode,
            purchaseQuantity: ele.purchaseQuantity,
            quantityShipped: ele.quantityShipped ? ele.quantityShipped : 0
          };
          skuCodeList.push(param);
        });
      }
      localStorage.setItem("skuCodeList", JSON.stringify(skuCodeList));
      localStorage.setItem("selectOrderSn", item.orderSn);
      if (
        typeof this.logisticsForm.getFieldsValue().logisticsName !== "undefined"
      ) {
        localStorage.setItem(
          "logisticsName",
          this.logisticsForm.getFieldsValue().logisticsName
        );
      }
      if (
        typeof this.logisticsForm.getFieldsValue().logisticsTel !== "undefined"
      ) {
        localStorage.setItem(
          "logisticsTel",
          this.logisticsForm.getFieldsValue().logisticsTel
        );
      }
      if (
        typeof this.logisticsForm.getFieldsValue().logisticsSn !== "undefined"
      ) {
        localStorage.setItem(
          "logisticsSn",
          this.logisticsForm.getFieldsValue().logisticsSn
        );
      }
      localStorage.setItem("senderInfo", JSON.stringify(this.senderInfo));
      localStorage.setItem("senderAddress", this.senderAddress);
      if (this.childOrderList && this.childOrderList.length > 0) {
        localStorage.setItem(
          "childOrderList",
          JSON.stringify(this.childOrderList)
        );
      }
    },
    // 判断订单是否可以修改收货地址
    judgeSenderAddressEdit(data) {
      for (const item of data) {
        if (item.quantityShipped && item.quantityShipped > 0) {
          return false;
        }
      }
      return true;
    },
    // 保存选择的物流公司
    onSearch(value) {
      const params = {
        factoryId: localStorage.getItem("factoryId"),
        keyword: value
      };
      onSearchLogistics(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.searchList = data;
          if (data && data.length > 0) {
            this.logisticsList = [];
            data.forEach(item => {
              const obj = {
                value: item.companyName,
                label: item.companyName
              };
              this.logisticsList.push(obj);
            });
          } else {
            this.logisticsList = [];
            this.logisticsList.push({ label: value, value: value });
          }
        }
      });
    },
    onChangeLogisticsName(e) {
      if (e) {
        localStorage.setItem("logisticsName", e);
      }
      if (this.searchList && this.searchList.length > 0) {
        this.searchList.forEach(item => {
          if (item.companyName === e) {
            this.logisticsForm.setFieldsValue({
              logisticsTel: item.companyTel
            });
          }
        });
      }
    },
    // 保存输入的手机号
    onChangeLogisticsTel(e) {
      if (e) {
        localStorage.setItem("logisticsTel", e.data);
      }
    },
    // 保存输入的物流单号
    onInputLogisticsSn(e) {
      const logisticsSn = e.target.value;
      if (logisticsSn) {
        localStorage.setItem("logisticsSn", logisticsSn);
      }
    },
    // 验证输入的发货数量是否超过购买数量
    validateShippingQuantity(orderSku) {
      if (!isNaN(orderSku.shippingQuantity)) {
        orderSku.shippingQuantity = Math.ceil(orderSku.shippingQuantity);
        const quantityShipped = orderSku.quantityShipped
          ? orderSku.quantityShipped
          : 0;
        if (
          orderSku.shippingQuantity + quantityShipped >
          orderSku.purchaseQuantity
        ) {
          orderSku.shippingQuantity =
            orderSku.purchaseQuantity - quantityShipped;
        }
      }
    },
    // 手机号码校验
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (value && !reg.test(value)) {
        callbackFn("手机号码格式不正确");
        return;
      }
      callbackFn();
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}

.last-info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 80px 20px;
  background-color: white;
}

.info-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.button-container {
  display: flex;
}

.button-item {
  margin-right: 15px;
}

.numberIpt {
  width: 100%;
}

.buyer-info {
  box-sizing: border-box;
  padding: 16px;
  background: rgba(67, 104, 246, 0.2);
  display: flex;
  align-items: center;
}

.order-info {
  box-sizing: border-box;
  padding: 16px;
  background: rgba(67, 104, 246, 0.1);
  display: flex;
}

/deep/ .ant-table-thead > tr > th {
  background: #fafafa !important;
}

.label-name {
  font-weight: bold;
}

.label-name span {
  font-weight: 400;
  margin-right: 10px;
}

.row {
  width: 100%;
}

.deliver-info {
  display: flex;
  align-items: center;
}

.deliver-info span {
  margin-right: 10px;
}
.col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-edit {
  margin-left: 10px;
}

.commodity-table {
  margin-bottom: 20px;
}
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
